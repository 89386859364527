class NavBar extends HTMLElement {
    constructor() {
        super();
        this.navObserver = null;
        this.handleScroll = this.handleScroll.bind(this);
        this.handleHTMXAfterSwap = this.handleHTMXAfterSwap.bind(this);
    }

    connectedCallback() {
        this.innerHTML = /*html*/ `<div class="hidden lg:block fixed px-1 py-5 text-lg bg-white/70 dark:bg-black/70 backdrop-blur-2xl rounded-full transform -translate-x-1/2 top-3 left-1/2 z-20 text-nowrap">
            <div id="highlight" class="absolute inset-y-1 -z-10 bg-primary-700/50 border-primary-800 rounded-full "></div>
            <a href="/" class="px-5 py-5 font-bold text-black/90 dark:text-white/80 nav-link">Start</a>
            <a href="/#websites" hx-swap="show:window:unset" class="px-5 py-5 font-bold text-gray-800/90 dark:text-white/60 nav-link">Websites</a>
            <a href="/#process" hx-swap="show:window:unset" class="px-5 py-5 font-bold text-gray-800/90 dark:text-white/60 nav-link">Prozess</a>
            <a href="/#pricing" hx-swap="show:window:unset" class="px-5 py-5 font-bold text-gray-800/90 dark:text-white/60 nav-link">Preise</a>
            <a href="/#faq" hx-swap="show:window:unset" class="px-5 py-5 font-bold text-gray-800/90 dark:text-white/60 nav-link">FAQ</a>
            <a href="/blog" id="blog-link" class="px-5 py-5 font-bold text-black/90 dark:text-white/80 nav-link">Blog</a>
        </div>
        `;

        // Initiale Navigation-Logik starten
        this.initNavbar();

        // Scroll-Event zur dynamischen Schattenvergabe an der Navbar
        window.addEventListener('scroll', this.handleScroll);
        document.body.addEventListener('htmx:afterSwap', this.handleHTMXAfterSwap);
    }

    disconnectedCallback() {
        if (this.navObserver) {
            this.navObserver.disconnect();
            this.navObserver = null;
        }
        window.removeEventListener('scroll', this.handleScroll);
        document.body.removeEventListener('htmx:afterSwap', this.handleHTMXAfterSwap);
    }

    handleScroll() {
        if (window.scrollY > 0) {
            this.classList.add('shadow-lg');
        } else {
            this.classList.remove('shadow-lg');
        }
    }
    handleHTMXAfterSwap(event) {
        this.initNavbar();
    }

    initNavbar() {
        // Vorherigen Observer (z. B. aus einem vorigen Seitenaufruf) stoppen
        if (this.navObserver) {
            this.navObserver.disconnect();
            this.navObserver = null;
        }

        // Wichtige Elemente im Light DOM der Komponente selektieren
        const highlight = this.querySelector('#highlight');
        const navLinks = this.querySelectorAll('.nav-link');
        if (!highlight || navLinks.length === 0) return;
        let currentActiveLink = null;

        // Aktueller Pfad und Hash ermitteln
        const currentPath = window.location.pathname;
        let currentHash = window.location.hash.replace(/^#/, '');
        if (currentHash.includes('?')) {
            currentHash = currentHash.split('?')[0];
        }

        // 1) Für Blog-Seiten den speziellen Link auswählen
        if (currentPath.startsWith('/blog')) {
            const blogLink = this.querySelector('#blog-link');
            if (blogLink) {
                currentActiveLink = blogLink;
            }
        } else {
            // 2) Wenn ein Hash vorhanden ist, versuchen wir, den passenden Link zu finden
            if (currentHash) {
                const possibleLink = this.querySelector(`a[href="/#${currentHash}"]`) ||
                    this.querySelector(`a[href="#${currentHash}"]`);
                if (possibleLink) {
                    currentActiveLink = possibleLink;
                }
            }
            // 3) Fallback: Ersten Link wählen
            if (!currentActiveLink) {
                currentActiveLink = navLinks[0];
            }
        }

        // Helper: Highlight verschieben
        const moveHighlight = (element, withTransition = true) => {
            if (!element) return;
            highlight.style.transition = withTransition ? 'all 0.12s' : 'none';
            highlight.style.width = element.offsetWidth + 'px';
            highlight.style.left = element.offsetLeft + 'px';
        };

        // Klick- und Hover-Events an alle Nav-Links binden
        navLinks.forEach(link => {
            // Bei Klick: Setze den aktuellen aktiven Link
            link.addEventListener('click', () => {
                currentActiveLink = link;
                moveHighlight(link);
            });
            // Bei Hover: Highlighter auf das überfahrene Element verschieben
            link.addEventListener('mouseenter', () => {
                moveHighlight(link);
            });
            // Bei Mouseleave: Highlighter zurücksetzen auf den aktuell aktiven Link
            link.addEventListener('mouseleave', () => {
                moveHighlight(currentActiveLink);
            });
        });

        // IntersectionObserver (nur, wenn nicht auf Blog-Seiten)
        if (!currentPath.startsWith('/blog')) {
            this.navObserver = new IntersectionObserver(
                entries => {
                    entries.forEach(entry => {
                        if (entry.target.id === 'about-me' && entry.isIntersecting) {
                            currentActiveLink = navLinks[0];
                            moveHighlight(currentActiveLink);
                        } else if (entry.isIntersecting) {
                            // Bestehende Logik für die anderen Abschnitte
                            const sectionId = entry.target.id;
                            const activeLink = document.querySelector(
                                `a[href="/#${sectionId}"], a[href="#${sectionId}"]`
                            );
                            if (activeLink) {
                                currentActiveLink = activeLink;
                                moveHighlight(activeLink);
                            }
                        }
                    });

                }, {
                    rootMargin: '-25% 0px -25% 0px',
                    threshold: 0.1
                }
            );

            // Für alle Nav-Links, die auf einen Abschnitt verweisen, den entsprechenden Abschnitt beobachten
            navLinks.forEach(link => {
                const href = link.getAttribute('href') || '';
                if (href.startsWith('/#')) {
                    const anchor = href.split('#')[1];
                    const section = document.getElementById(anchor);
                    if (section) {
                        this.navObserver.observe(section);
                    }
                    const topSentinel = document.getElementById('about-me');
                    if (topSentinel) {
                        this.navObserver.observe(topSentinel);
                    }

                }
            });
        }

        // Initiales Highlight setzen
        moveHighlight(currentActiveLink, false);
    }
}

// Beachte: Custom Element-Namen müssen mindestens einen Bindestrich enthalten.
customElements.define('nav-bar', NavBar);