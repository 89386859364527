// All JS files are sent to public via esbuild, as if it was an eleventy passthrough. This can be configured like any other bundler
import htmx from 'htmx.org';

import "./3dbutton";
import "./nav-bar";
import "./nav-menu";
// import "./dark-mode";
// import "./touchhover";
// import "./rotating-text";

htmx.config.scrollIntoViewOnBoost = false;

document.body.addEventListener('htmx:afterSwap', function(evt) {
    if (window.location.pathname === '/blog') {
      window.scrollTo(0, 0);
      console.log("asdas");
    }
  });