class NavMenu extends HTMLElement {
    constructor() {
        super();
        // Bindet den Swap-Callback, damit 'this' korrekt referenziert wird.
        this._onAfterSwap = this._onAfterSwap.bind(this);
    }

    connectedCallback() {
        // Light DOM: Wir fügen den HTML-Inhalt direkt ein, damit Tailwind unberührt bleibt.
        this.innerHTML = /*html*/ `
        
        <!-- Mobiler Menü-Button -->
        <div id="menuButton" class="bg-white/70 dark:bg-black/40 backdrop-blur-2xl shadow-lg rounded-full px-5 py-3 text-gray-800 dark:text-white/70 font-bold z-20 cursor-pointer hover:bg-opacity-90 transition-colors duration-300">
            Menü
        </div>
        
        <!-- Bubble-Overlay für das Mobile-Menü -->
        <div id="mobileMenu" class="fixed inset-0 flex items-center justify-center bg-white/70 dark:bg-black/40 backdrop-blur-2xl scale-0 origin-top-right transition-transform duration-100 z-50">
            <!-- X-Button (Close) -->
            <div id="menuCloseButton" class="absolute top-5 right-5 text-xl text-green-800 dark:text-white/50 font-bold cursor-pointer select-none">
                zurück &times;
            </div>
        
            <!-- Die eigentlichen Menü-Links (groß für Mobile) -->
            <ul class="text-center text-3xl text-gray-800 dark:text-white/70 font-bold">
                <li class="py-3">
                    <a href="/" class="bubble-link"> Start </a>
                </li>
                <li class="py-3">
                    <a href="/blog" class="bubble-link"> Blog </a>
                </li>
                <li class="py-3">
                    <a href="mailto:kontakt@martingawron.de" class="bubble-link">Kontakt</a>
                </li>
            </ul>
        </div>
        
        `;

        // Menü-Initialisierung und Eventlistener setzen
        this.setupMenu();

        // Globalen Event-Listener für htmx-Swaps (bzw. pjax) registrieren,
        // sodass das Menü immer geschlossen wird, wenn neuer Inhalt geladen wird.
        document.addEventListener("htmx:afterSwap", this._onAfterSwap);

        // Prüfe, ob das Flag "bubbleOpen" gesetzt wurde (Navigation via bubble-link)
        if (localStorage.getItem("bubbleOpen") === "true") {
            localStorage.removeItem("bubbleOpen");
            this.setMenuState(true, true); // Sofort öffnen, ohne Animation
            setTimeout(() => {
                this.setMenuState(false);
            }, 50);
        }
    }

    disconnectedCallback() {
        document.removeEventListener("htmx:afterSwap", this._onAfterSwap);
    }

    // Entfernt temporär Transition-Klassen
    disableAnimationTemporarily(element) {
        element.classList.remove("transition-transform", "duration-100");
    }

    // Fügt Transition-Klassen wieder hinzu
    enableAnimation(element) {
        element.classList.add("transition-transform", "duration-100");
    }

    // Setzt den Zustand des Menüs (offen/geschlossen); noAnimation steuert, ob Animationen genutzt werden sollen
    setMenuState(isOpen, noAnimation = false) {
        const mobileMenu = this.querySelector("#mobileMenu");
        const menuButton = this.querySelector("#menuButton");
        if (!mobileMenu || !menuButton) return;

        if (noAnimation) {
            this.disableAnimationTemporarily(mobileMenu);
        }

        if (isOpen) {
            mobileMenu.classList.add("scale-100");
            menuButton.textContent = "X";
            document.body.classList.add("overflow-hidden");
        } else {
            mobileMenu.classList.remove("scale-100");
            menuButton.textContent = "Menü";
            document.body.classList.remove("overflow-hidden");
        }

        if (noAnimation) {
            // Erzwinge einen Reflow, damit die Änderung sofort wirkt
            mobileMenu.offsetHeight;
            setTimeout(() => {
                this.enableAnimation(mobileMenu);
            }, 0);
        }
    }

    setupMenu() {
        const mobileMenu = this.querySelector("#mobileMenu");
        const menuButton = this.querySelector("#menuButton");
        const menuCloseButton = this.querySelector("#menuCloseButton");

        if (!mobileMenu || !menuButton || !menuCloseButton) return;

        // Beim Klick auf einen bubble-link wird das Flag gesetzt,
        // damit das Menü auf der nächsten Seite sofort offen ist.
        this.querySelectorAll("a.bubble-link").forEach((link) => {
            link.addEventListener("click", () => {
                localStorage.setItem("bubbleOpen", "true");
            });
        });

        // Menü öffnen/schließen beim Klick auf den Button
        menuButton.addEventListener("click", () => {
            if (mobileMenu.classList.contains("scale-100")) {
                this.setMenuState(false);
                mobileMenu.classList.remove("bg-white/70");
                mobileMenu.classList.add("bg-white");
            } else {
                this.setMenuState(true);
                mobileMenu.classList.remove("bg-white");
                mobileMenu.classList.add("bg-white/70");
            }
        });

        // Menü beim Klick auf den Schließen-Button schließen
        menuCloseButton.addEventListener("click", () => {
            this.setMenuState(false);
        });
    }

    // Callback-Funktion, die aufgerufen wird, wenn htmx (bzw. pjax) neue Inhalte lädt
    _onAfterSwap() {
        this.setMenuState(false);
    }
}

// Registriere das Custom Element unter dem Namen <nav-menu>
customElements.define("nav-menu", NavMenu);